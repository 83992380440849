import React from "react";
import {
  SorteioCardIcon,
  SorteioSectionCard,
  SorteioSectionContainer,
  SorteioSectionTitle,
  WrapperSectionCardMobile,
} from "./styles";
import { getSlug } from "utils/helper";
import { RenderCarousel } from "./CarouselRenderer";
import { useStore } from "configureStore";
import { useRouter } from "hooks/useRouter";
import useWindowSize from "hooks/useWIndowSize";
import useScrollToModalLogin from "hooks/useScrollToModalLogin";

import IconMobile from "assets/icons/onda/mobile.svg";
import IconBomba from "assets/images/grandeGp/bomba.svg";
import IconClover from "assets/images/grandeGp/clover.png";
import IconTicket from "assets/icons/icon-ticket.svg";
import IconTroca from "assets/icons/icon-troca-gray.svg";

export function SorteioSection2024() {
  const isAuth = useStore((state) => state.auth.isAuthenticated);
  const slug = getSlug().url;
  const router = useRouter();
  const scrollToModal = useScrollToModalLogin();
  const { width } = useWindowSize();
  const isDesktop = width >= 1280;
  const isMobile375 = width <= 375;

  function handleCardItemClick() {
    if (isAuth) {
      router.push(`${slug}/gp-sp-2023/numeros-da-sorte`);
    }
    scrollToModal();
  }

  // if (isMobile375) {
  //   return (
  //     <SorteioSectionContainer>
  //       <SorteioSectionTitle>
  //         Participe do sorteio com Shell Box e Shell V-Power
  //       </SorteioSectionTitle>
  //       <WrapperSectionCardMobile>
  //         <SorteioSectionCard>
  //           <SorteioCardIcon>
  //             <img src={IconMobile} alt="Ícone de um celular" />
  //           </SorteioCardIcon>
  //           <span>passo 1</span>
  //           <strong>
  //             Baixe o Aplicativo Shell Box e participe do Shell Box Clube
  //           </strong>
  //           <p>
  //             Você precisa ler e concordar com o regulamento para participar do
  //             Shell Box Clube e das promoções do Shell Box.
  //             <br />
  //             <br />
  //             Disponível para IOS e Android
  //           </p>
  //         </SorteioSectionCard>
  //         <SorteioSectionCard>
  //           <SorteioCardIcon>
  //             <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
  //           </SorteioCardIcon>
  //           <span>passo 2</span>
  //           <strong>Abasteça pelo app Shell Box e ganhe stix</strong>
  //           <p>
  //             Abastecendo R$ 50 com Shell V-Power ou R$ 100,00 com combustíveis
  //             comuns, via Shell Box, você ganha 20 pontos stix para trocar por 1
  //             número da sorte.
  //           </p>
  //         </SorteioSectionCard>
  //         <SorteioSectionCard>
  //           <SorteioCardIcon>
  //             <img src={IconTroca} alt="Ícone de trevo de quatro folhas" />
  //           </SorteioCardIcon>
  //           <span>passo 3</span>
  //           <strong>Acesse o Shell Box Clube</strong>
  //           <p>
  //             E troque 20 pontos stix por 1 número da sorte para concorrer aos
  //             sorteios semanais.
  //             <br />
  //             <br />
  //             Em cada nível, você poderá participar 5 vezes. Basta clicar em
  //             “ver tudo” no bloco “parcerias” no menu do Shell Box Clube.
  //           </p>
  //         </SorteioSectionCard>
  //         <SorteioSectionCard>
  //           <SorteioCardIcon>
  //             <img src={IconTicket} alt="Ícone de um ingresso" />
  //           </SorteioCardIcon>
  //           <span>passo 4</span>
  //           <strong>Concorra a prêmios</strong>
  //           <p>
  //             Com o número da sorte você concorre a 1 par de ingressos para
  //             assistir ao Grande Prêmio de São Paulo 2024, ou produtos
  //             exclusivos Shell Coleção Ferrari, ou ainda, cupons de desconto de
  //             R$ 200 para abastecer com o Shell Box.
  //           </p>
  //         </SorteioSectionCard>
  //         <SorteioSectionCard>
  //           <SorteioCardIcon>
  //             <img src={IconClover} alt="Ícone de trevo de quatro folhas" />
  //           </SorteioCardIcon>
  //           <span>passo 5</span>
  //           <strong>Cruze os dedos e acompanhe os sorteios!</strong>
  //           <p>
  //             Confira seus números da sorte através do seu email cadastrado no
  //             Shell Box e fique {"atento(a)"} as datas do sorteio. Boa sorte!
  //           </p>
  //         </SorteioSectionCard>
  //       </WrapperSectionCardMobile>
  //       <span>* Confira o regulamento da promoção e saiba mais.</span>
  //     </SorteioSectionContainer>
  //   );
  // }

  return (
    <SorteioSectionContainer>
      <SorteioSectionTitle>
        Participe do sorteio com Shell Box e Shell V-Power
      </SorteioSectionTitle>

      <RenderCarousel>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconMobile} alt="Ícone de um celular" />
          </SorteioCardIcon>
          <span>passo 1</span>
          <strong>
            Baixe o Aplicativo Shell Box e participe do Shell Box Clube
          </strong>
          <p>
            Você precisa ler e concordar com o regulamento para participar do
            Shell Box Clube e das promoções do Shell Box.
            <br />
            <br />
            Disponível para IOS e Android
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconBomba} alt="Ícone de uma bomba de gasolina" />
          </SorteioCardIcon>
          <span>passo 2</span>
          <strong>
            Abasteça pelo app Shell Box <br /> e ganhe stix
          </strong>
          <p>
            Abastecendo R$50,00 com a família Shell V-Power ou R$100,00 com
            combustíveis comuns, através do Shell Box, você conquista 20 pontos
            stix para trocar por 1 número da sorte.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconTroca} alt="" />
          </SorteioCardIcon>
          <span>passo 3</span>
          <strong>Acesse o Shell Box Clube</strong>
          <p>
            E troque 20 pontos stix por 1 número da sorte para concorrer aos
            sorteios.
            <br />
            <br />
            Em cada período de participação, você poderá participar até 5 vezes.
            Verifique se não há nenhuma atualização no app Shell Box, vá ao
            bloco “parcerias” no menu do Shell Box Clube e visualize todas as
            ofertas em “ver tudo”.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconTicket} alt="Ícone de um ingresso" />
          </SorteioCardIcon>
          <span>passo 4</span>
          <strong>Concorra a prêmios</strong>
          <p>
            Com o número da sorte você concorre a 1 par de ingressos para
            assistir ao Grande Prêmio de São Paulo 2024, ou produtos exclusivos
            Shell coleção Ferrari, ou ainda, cupons de desconto de R$ 200 para
            abastecer com o Shell Box.
          </p>
        </SorteioSectionCard>
        <SorteioSectionCard>
          <SorteioCardIcon>
            <img src={IconClover} alt="Ícone de trevo de quatro folhas" />
          </SorteioCardIcon>
          <span>passo 5</span>
          <strong>Cruze os dedos e acompanhe os sorteios!</strong>
          <p>
            Confira seus números da sorte através do seu email cadastrado no
            Shell Box e fique {"atento(a)"} as datas do sorteio. Boa sorte!
          </p>
        </SorteioSectionCard>
      </RenderCarousel>

      <span>* Confira o regulamento da promoção e saiba mais.</span>
    </SorteioSectionContainer>
  );
}
