import styled from "styled-components";

export const SorteioSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: calc(110% - 10% + (10vw / 2));
  margin-left: 5vw;
  gap: 2rem;

  & > span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(props) => props.theme.color.text};
    /* margin-top: -1.25rem; */
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    width: 100%;
    margin-left: unset;

    & > span {
      margin-left: 4%;
    }
  }
`;

export const SorteioSectionTitle = styled.h1`
  font-size: 1.125rem;
  line-height: normal;
  font-weight: 700;
  font-family: ${(props) => props.theme.Shell.Bold};
  color: ${(props) => props.theme.color.gray};
  /* text-transform: uppercase; */
  text-align: center;
  width: 60%;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    font-size: 1.5625rem;
    width: 100%;
  }
`;

export const SorteioSectionCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  width: 100%;
  max-width: 228px;
  /* min-height: 352px; */
  min-height: 386px;

  background: ${(props) => props.theme.color.white};
  padding: 1rem;
  margin: 2.5rem 0 0;
  border-radius: 12px;

  box-shadow: 0px 2px 4px rgba(64, 64, 64, 0.15);
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  &:last-child {
    margin-bottom: unset;
  }
  // Passo {numero}
  & > span {
    font-family: ${(props) => props.theme.helvetica};
    font-weight: bold;
    font-size: 0.75rem;
    line-height: normal;
    color: ${(props) => props.theme.color.text};
    border-radius: 3px;
    background: #d9d9d9;
    padding: 2px;
    width: 52px;
    box-sizing: border-box;
    text-transform: capitalize;
  }
  // Título Cinza
  strong {
    font-size: 1.125rem;
    line-height: 1.1;
    font-weight: bold;
    font-family: ${(props) => props.theme.Shell.Bold};
    color: ${(props) => props.theme.color.gray};
  }
  // Texto do corpo
  p {
    font-family: Arial;
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${(props) => props.theme.color.gray};
    margin: 0;
    align-self: flex-start;

    a {
      color: ${(props) => props.theme.color.blue};
    }

    button {
      border: 0;
      background: transparent;
      color: ${(props) => props.theme.color.red};
      padding: 0;
      margin: 0;
      font-family: inherit;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    & > span {
      white-space: nowrap;
      /* margin-left: 4px; */
    }
  }

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    max-width: 15.5rem;
    margin-top: 3.25rem;
    max-height: max-content;
    gap: 1.5rem;
  }
`;

export const SorteioCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 82px;
  min-height: 82px;
  color: ${(props) => props.theme.color.gray};

  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.color.white};
  background: ${(props) => props.theme.color.yellow};
  margin-top: -3.5rem;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
`;

export const SorteioCardWrapper = styled.article<{ isRedeemTicket: boolean }>`
  display: flex;
  justify-content: center;
  gap: ${(props) => (props.isRedeemTicket ? "34px" : "10px")};
  width: 100%;
`;

export const WrapperSectionCardMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;
