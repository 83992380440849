import React from "react";
import {
  Container,
  ContainerTickets,
  LabelTickets,
  DescriptionTickets,
  Bold,
  ContainerSteps,
  WrapperSteps,
} from "./styles";
import { RedeemTicketsCardsSection } from "modules/promoGrandeGP2024/components/ResgateDeIngressos/RedeemTicketsCardsSection";
import { RedeemTickets2024 } from "modules/promoGrandeGP2024/components/Home/RedeemTickets";
import { BatchReleaseSection } from "modules/promoGrandeGP2024/components/ResgateDeIngressos/BatchRelease";

export default function ResgateDeIngressosGp2024() {
  return (
    <Container>
      <ContainerTickets>
        <LabelTickets>
          <strong>Fique atento à data da pré-venda:</strong>
          <p>
            O resgate do par de ingressos estará disponível no Shell Box Clube a
            partir do nível 3 do programa de fidelidade na data 16/setembro.
          </p>
        </LabelTickets>
        {/* <DescriptionTickets>
          O próximo lote de resgate estará disponível no dia
          <Bold>25/10/2023 às 12h.</Bold>
        </DescriptionTickets> */}
      </ContainerTickets>
      <ContainerSteps>
        <WrapperSteps>
          <RedeemTicketsCardsSection />
          <BatchReleaseSection />
          <RedeemTickets2024 />
        </WrapperSteps>
      </ContainerSteps>
    </Container>
  );
}
