import { Ticket } from "modules/promoGrandeGP2024/pages/NumerosDaSorte/styles";
import React from "react";
import styled from "styled-components";

export function BatchReleaseSection() {
  return (
    <Container>
      <Content>
        <h1>Confira abaixo as datas de liberação de lotes:</h1>
        <TicketContent>
          <TicketContainer>
            <TicketGrayContent>
              <strong>Lote pré-venda</strong>
              <span>{"(a partir do nível 3)"}</span>
            </TicketGrayContent>
            <TicketDate>16/09/2024</TicketDate>
          </TicketContainer>
          <TicketContainer>
            <TicketGrayContent>
              <strong>Lote 1</strong>
              <span>{"(a partir do nível 2)"}</span>
            </TicketGrayContent>
            <TicketDate>23/09/2024</TicketDate>
          </TicketContainer>
          <TicketContainer>
            <TicketGrayContent>
              <strong>Lote 2</strong>
              <span>{"(a partir do nível 2)"}</span>
            </TicketGrayContent>
            <TicketDate>30/09/2024</TicketDate>
          </TicketContainer>
          <TicketContainer>
            <TicketGrayContent>
              <strong>Lote 3</strong>
              <span>{"(a partir do nível 2)"}</span>
            </TicketGrayContent>
            <TicketDate>07/10/2024</TicketDate>
          </TicketContainer>
          <p>
            Fique atento(a) a essas datas para aproveitar a corrida do Grande
            Prêmio de São Paulo na arquibancada exclusiva da Shell!
          </p>
        </TicketContent>
      </Content>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: ${(props) => props.theme.color.white};

  padding: 60px 0;
`;

const Content = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  gap: 32px;

  margin: 0 auto;

  & > h1 {
    font-family: ${(props) => props.theme.Shell.Bold};
    font-size: 1.125rem;
    line-height: 1.375;
    color: ${(props) => props.theme.color.text};
    text-align: center;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.5rem;
      margin: 0;
    }
  }
`;

const TicketContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 622px;

  gap: 14px;

  & > span {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1;
    color: ${(props) => props.theme.color.text};
    text-align: center;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.125rem;
      line-height: 1.3;
    }
  }

  & > p {
    font-family: ${(props) => props.theme.Shell.Book};
    font-size: 1rem;
    line-height: 1.25;
    color: ${(props) => props.theme.color.text};
    text-align: left;

    @media (min-width: ${(props) => props.theme.queries.lg}) {
      font-size: 1.125rem;
      line-height: 1.3;
    }
  }
`;

const TicketContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 370px;
  border: 1px solid ${(props) => props.theme.color.lightGray200};
`;

const TicketGrayContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 68px;
  max-width: 184px;

  background-color: ${(props) => props.theme.color.lightGray};
  border-right: 1px solid ${(props) => props.theme.color.lightGray200};

  strong,
  span {
    font-family: ${(props) => props.theme.Shell.Heavy};
    line-height: 1.5rem;
    text-align: center;
  }
  strong {
    font-size: 1.25rem;
    color: ${(props) => props.theme.color.text};
  }
  span {
    font-size: 0.875rem;
    color: ${(props) => props.theme.color.gray600};
  }
`;

const TicketDate = styled.div`
  width: 100%;
  max-width: 186px;
  padding: 0 42px;

  font-size: 1rem;
  line-height: 1.25;
  font-family: ${(props) => props.theme.Shell.Book};
  color: ${(props) => props.theme.color.text};
`;
