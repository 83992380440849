import React, { useState } from "react";
import {
  GanhadoresContainer,
  GanhadoresContent,
  GanhadoresNoticeBlock,
  NumerosDaSorteTabWrapper,
} from "./styles";
import { NumerosDaSorteTab } from "../NumerosDaSorte/styles";
import { GanhadoresTable } from "./GanhadoresTable";
// data
import ganhadores14082024 from "json/ganhadores-gp-2024/ganhadores-14-08-2024.json";
import ganhadores24082024 from "json/ganhadores-gp-2024/ganhadores-24-08-2024.json";
import ganhadores04092024 from "json/ganhadores-gp-2024/ganhadores-04-09-2024.json";
import ganhadores14092024 from "json/ganhadores-gp-2024/ganhadores-14-09-2024.json";

export default function GanhadoresGp2024() {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <GanhadoresContainer>
      <GanhadoresNoticeBlock>
        <p>
          Atenção! O próximo sorteio da Loteria Federal será realizado em
          04/09/2024
        </p>
      </GanhadoresNoticeBlock>
      <NumerosDaSorteTabWrapper>
        <NumerosDaSorteTab
          aria-selected={currentTab === 0}
          onClick={() => setCurrentTab(0)}
        >
          <span>Sorteio</span>
          <span>Dia 14/08/2024</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 1}
          onClick={() => setCurrentTab(1)}
        >
          <span>Sorteio</span>
          <span>Dia 24/08/2024</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 2}
          onClick={() => setCurrentTab(2)}
        >
          <span>Sorteio</span>
          <span>Dia 04/09/2024</span>
        </NumerosDaSorteTab>
        <NumerosDaSorteTab
          aria-selected={currentTab === 3}
          onClick={() => setCurrentTab(3)}
        >
          <span>Sorteio</span>
          <span>Dia 14/09/2024</span>
        </NumerosDaSorteTab>
      </NumerosDaSorteTabWrapper>
      <GanhadoresContent>
        {currentTab === 0 && (
          <>
            <p>Participação de 01/08 a 10/08/2024</p>
            {/* <span>
              Nossa central de atendimento entrará em contato com os possíveis
              ganhadores em até 5 dias úteis após a realização do sorteio pela
              Loteria Federal, por isso fique atento(a) que podemos estar
              tentando falar com você! Os ganhadores serão divulgados em até 30
              dias após iniciados os contatos.
            </span> */}
            <GanhadoresTable tableData={ganhadores14082024} />
          </>
        )}
        {currentTab === 1 && (
          <>
            <p>Participação de 11/08 a 20/08/2024</p>
            <span>
              Nossa central de atendimento entrará em contato com os possíveis
              ganhadores em até 5 dias úteis após a realização do sorteio pela
              Loteria Federal, por isso fique atento(a) que podemos estar
              tentando falar com você! Os ganhadores serão divulgados em até 30
              dias após iniciados os contatos.
            </span>
            {/* <GanhadoresTable tableData={ganhadores24082024} /> */}
          </>
        )}
        {currentTab === 2 && (
          <>
            <p>Participação de 21/08 a 30/08/2024</p>
            <span>
              Nossa central de atendimento entrará em contato com os possíveis
              ganhadores em até 5 dias úteis após a realização do sorteio pela
              Loteria Federal, por isso fique atento(a) que podemos estar
              tentando falar com você! Os ganhadores serão divulgados em até 30
              dias após iniciados os contatos.
            </span>
            {/* <GanhadoresTable tableData={ganhadores04092024} /> */}
          </>
        )}
        {currentTab === 3 && (
          <>
            <p>Participação de 31/08 a 09/09/2024</p>
            <span>
              Nossa central de atendimento entrará em contato com os possíveis
              ganhadores em até 5 dias úteis após a realização do sorteio pela
              Loteria Federal, por isso fique atento(a) que podemos estar
              tentando falar com você! Os ganhadores serão divulgados em até 30
              dias após iniciados os contatos.
            </span>
            {/* <GanhadoresTable tableData={ganhadores14092024} /> */}
          </>
        )}
      </GanhadoresContent>
    </GanhadoresContainer>
  );
}
