import React from "react";
import styled from "styled-components";
import { QrCodeSection2024 } from "modules/promoGrandeGP2024/components/Home/QrCode";
import { SorteioSection2024 } from "modules/promoGrandeGP2024/components/Home/SorteioSection";
import { RedeemTickets2024 } from "modules/promoGrandeGP2024/components/Home/RedeemTickets";
import { ParticipationPeriod } from "modules/promoGrandeGP2024/components/Home/ParticipationPeriod";

export default function HomeGP2024() {
  return (
    <HomeContainer>
      <QrCodeSection2024 />
      {/* <Gp2024VideoSection /> */}
      <SorteioSection2024 />
      <ParticipationPeriod />
      <RedeemTickets2024 />
    </HomeContainer>
  );
}

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.125rem;

  width: 90%;
  max-width: 1270px;

  margin: 40px auto 1.875rem;

  @media (min-width: ${(props) => props.theme.queries.desk}) {
    gap: 75px;
    margin: 5rem auto 4.625rem;
  }
`;
